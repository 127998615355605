import {Button, Collapse, CollapseProps, Flex, Switch, Typography} from "antd";
import React, {CSSProperties, useEffect, useState} from "react";
import {useTranslations} from "next-intl";
import useWindowDimensions from "@/src/hooks/useWindowDimensions";
import {MediaQueryEnum} from "@ant-design/pro-utils/lib/useMediaQuery";
import {CloseOutlined} from "@ant-design/icons";
import {LoggerService} from "@/src/services/logger";
import {Constants} from "@/src/lib/constants";
import {UserPreferences} from "@/src/entities/UserPreferences";

type Props = {
    onUserPropertySent: (properties: UserPreferences) => void
}

const CookieBannerComponent = (props: Props) => {

    const localization = useTranslations('CookieBanner');
    const screenWidth: number = useWindowDimensions().width
    const screenHeight: number = useWindowDimensions().height
    const [showPreferences, setShowPreferences] = useState<Boolean>(false)
    const [showBanner, setShowBanner] = useState<Boolean>(false)
    const [isFunctionalOn] = useState<boolean>(true)
    const [isStatisticsOn, setStatistics] = useState<boolean>(true)
    const [isMarketingOn, setMarketing] = useState<boolean>(true)


    const containerStyle: CSSProperties = {
        position: "absolute",
        bottom: 10,
        left: screenWidth >= MediaQueryEnum.sm.minWidth ? 10 : 0,
        width: screenWidth >= MediaQueryEnum.sm.minWidth ? 390 : "100%",
        backgroundColor: "#FFF",
        padding: 16,
        zIndex: 1001,
        border: "1px solid transparent",
        borderRadius: 6,
        boxShadow: "5px 9px 15px 1px rgba(0,0,0,0.33)",
        overflow: "hidden"
    }

    const textContentStyle: CSSProperties = {
        overflow: "scroll",
        maxHeight: screenHeight / 100 * 60
    }

    useEffect(() => {
        setShowBanner(true)
    }, []);

    const retrieveCollapseItem = (): CollapseProps['items'] => {

        const preferencesList = [
            {
                "id": "functional",
                "title": localization("preferences.functional.title"),
                "text": localization("preferences.functional.text"),
                "optional": false,
                "isOn": true
            },
            {
                "id": "statistics",
                "title": localization("preferences.statistics.title"),
                "text": localization("preferences.statistics.text"),
                "optional": true,
                "isOn": false
            },
            {
                "id": "marketing",
                "title": localization("preferences.marketing.title"),
                "text": localization("preferences.marketing.text"),
                "optional": true,
                "isOn": false
            }
        ]


        return preferencesList.map((value, index) => {
            let isOn: boolean = false
            switch (value.id) {
                case "functional":
                    isOn = isFunctionalOn
                    break
                case "statistics":
                    isOn = isStatisticsOn
                    break
                case "marketing":
                    isOn = isMarketingOn
                    break
                default:
                    break
            }

            const onSwitchClick = (id: string, isOn: boolean, event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLButtonElement>) => {
                LoggerService.getLogger().debug(`Switch clicked ${id}`)
                event.stopPropagation();
                switch (id) {
                    case "statistics":
                        setStatistics(isOn)
                        break
                    case "marketing":
                        setMarketing(isOn)
                        break
                    default:
                        break
                }
            }

            return {
                key: index,
                label: value.title,
                children: value.text,
                extra: <Switch disabled={!value.optional} value={isOn} onClick={(clicked, event) => onSwitchClick(value.id, clicked, event)} />
            }
        })
    }

    const savePreferences = (prefs: UserPreferences) => {
        LoggerService.getLogger().debug(`<<< USER PREFS: ${JSON.stringify(prefs)}`)
        window.localStorage.setItem(Constants.userPrivacyPrefsKey, JSON.stringify(prefs))
        props.onUserPropertySent(prefs)
    }

    return (
        <>
            {(() => {
                if (showBanner) {
                    return <div style={containerStyle}>
                        <Flex vertical gap={16}>
                            <Flex vertical={false} justify={"space-between"} align={"center"}>
                                <Typography.Title level={5} style={{fontWeight: "normal"}}>{localization("title")}</Typography.Title>
                                <Button
                                    shape={"circle"}
                                    type={"link"}
                                    icon={<CloseOutlined style={{color: "#000", fontSize: 16, fontWeight: "bold"}}/>}
                                    onClick={()=>{
                                        const prefs: UserPreferences = {functional: true, statistics: false, marketing: false}
                                        savePreferences(prefs)
                                    }}
                                />
                            </Flex>
                            <div style={textContentStyle}>
                                <Typography.Paragraph>{localization("text")}</Typography.Paragraph>
                                {(() => {
                                    if (showPreferences) {
                                        return (
                                            <Flex vertical gap={16}>
                                                <Collapse
                                                    expandIconPosition={"end"}
                                                    items={retrieveCollapseItem()}
                                                />
                                            </Flex>
                                        )
                                    }
                                    return <></>
                                })()}
                            </div>
                            <Flex vertical={false} justify={"flex-end"} align={"center"} gap={8}>
                                <Button type={"primary"} onClick={()=>{
                                    const prefs: UserPreferences = {functional: true, statistics: true, marketing: true}
                                    savePreferences(prefs)
                                }}>{localization("acceptButton")}</Button>
                                <Button type={"primary"} danger onClick={()=>{
                                    const prefs: UserPreferences = {functional: true, statistics: false, marketing: false}
                                    savePreferences(prefs)
                                }}>{localization("denyButton")}</Button>
                                <Button type={"primary"} color={"gold"} variant={"solid"} onClick={()=> {
                                    if (showPreferences) {
                                        const prefs: UserPreferences = {functional: isFunctionalOn, statistics: isStatisticsOn, marketing: isMarketingOn}
                                        savePreferences(prefs)
                                    } else {
                                        setShowPreferences(true)}
                                    }
                                }>{showPreferences ? localization("prefButtonState2") : localization("prefButtonState1")}</Button>
                            </Flex>
                            <Button type={"link"} color={"default"} variant={"link"}>{localization("privacyPolicyButton")}</Button>
                        </Flex>

                    </div>
                } else {
                    return <></>
                }
            })()}
        </>
    )
}


export default CookieBannerComponent