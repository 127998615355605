
import { Observable } from 'rxjs';
import {Product} from "@/src/entities/product";
import {ProductInteractor} from "@/src/interactors/productInteractor";

export class ProductViewModel {

    private productInteractor: ProductInteractor

    constructor() {
        this.productInteractor = new ProductInteractor();
    }

    public retrieveProducts(): Observable<Product[]> {
        return this.productInteractor.retrieveProducts()
    }
}