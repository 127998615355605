import { NetworkResponse } from '@/src/network/models/networkResponse';
import { NetworkError } from '@/src/network/models/networkError';

export class NetworkResult<T> {
    response?: NetworkResponse<T>
    error?: NetworkError

    constructor(response?: NetworkResponse<T>, error?: NetworkError) {
        this.response = response
        this.error = error
    }
}