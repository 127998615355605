import { LoggerService } from '@/src/services/logger';
import {BaseRepository} from "@/src/repositories/BaseRepository";
import {Product} from "@/src/entities/product";
import {JsonClient} from "@/src/network/ws/jsonClient";
import {JsonService} from "@/src/network/jsonService";
import * as process from "process";
import {util} from "protobufjs";
import emptyArray = util.emptyArray;
import {NetworkResult} from "@/src/network/models/networkResult";

interface IProduct {
    retrieveProducts(): Promise<Product[]>
}

export class ProductRepository extends BaseRepository implements IProduct {

    private jsonService: JsonService

    public constructor() {
        super()
        this.jsonService = new JsonClient();
        LoggerService.getLogger().debug("create product interactor")
    }

    public async retrieveProducts(): Promise<Product[]> {
        if (typeof window !== 'undefined') {
            const basePath = `${window.location.protocol}//${window.location.host}`
            const endpoint =`${basePath}/jsons/products.json`
            LoggerService.getLogger().debug(`<<< ENDPOINT: ${endpoint}`)

            // const endpoint = "http://localhost:3000/jsons/products.json"

            const res = await this.jsonService.sendRequest<Product[]>(endpoint)
            return this.createNewPromise<Product[]>(res)
        }
        return this.createNewPromise<Product[]>({})
    }
}