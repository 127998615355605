import { BehaviorSubject } from 'rxjs';
import { LoggerService } from '@/src/services/logger';

export class LoaderService {
  private static instance: LoaderService
  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false)

  private constructor() {
      if(LoaderService.instance) {
          throw new Error("Error: Instantiation failed: Use LoaderService.getInstance() instead of new.");
      }
      LoggerService.getLogger().debug("Create LoaderService")
      LoaderService.instance = this;
  }

  static getInstance(): LoaderService {
     LoaderService.instance = LoaderService.instance || new LoaderService();
     return LoaderService.instance;
  }

  public show() {
    this.isLoading.next(true)
  }

  public hide() {
    this.isLoading.next(false)
  }

}