'use client'
import React, { useEffect, useState } from 'react';
import {useTranslations, useLocale} from 'next-intl';
import './home.css';

import {NotificationObj} from '@/src/components/notification';
import { LoaderService } from '@/src/services/loader';
import { LoggerService } from '@/src/services/logger';
import {Subscription} from "rxjs";
import {Helper} from "@/src/lib/helper";
import {
    Layout,
    ConfigProvider,
} from "antd";
import {Content, Footer, Header} from "antd/lib/layout/layout";
import {ProductViewModel} from "@/src/viewModels/productViewModel";
import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';
import {Product} from "@/src/entities/product";
import {MediaQueryEnum} from "@ant-design/pro-utils/lib/useMediaQuery";
import HeaderComponent from "@/src/app/[locale]/home/components/header";
import CarouselComponent from "@/src/app/[locale]/home/components/carousel";
import FooterComponent from "@/src/app/[locale]/home/components/footer";
import StatsComponent from "@/src/app/[locale]/home/components/stats";
import ContactComponent from "@/src/app/[locale]/home/components/contact";
import {inter} from "@/src/app/fonts";
import ProductsComponent from "@/src/app/[locale]/home/components/products";
import CompanyComponent from "@/src/app/[locale]/home/components/company";
import {useRouter} from "@/src/i18n/routing";
import CookieBanner from "@/src/app/[locale]/home/components/cookieBanner";
import { Constants } from '@/src/lib/constants';
import Script from "next/script";
import {UserPreferences} from "@/src/entities/UserPreferences";

// https://demo.templatemonster.com/it/demo/307998.html?_gl=1*1ye2zn7*_gcl_aw*R0NMLjE3MzMzODA4NzYuQ2p3S0NBaUFtTUM2QmhBNkVpd0FkTjVpTGY3S0IwS2pQenl0VkJGeXlNN29ZUF9hVGRVeUFNX3VVVTg2Q3J0eHpsSW5MVEY0enFjeVNSb0NzMjRRQXZEX0J3RQ..*_gcl_au*MTc0MDM1NTk1NS4xNzMzMzgwODQ0*_ga*NTU3MDM1OTk2LjE3MzMzODA4MzQ.*_ga_FTPYEGT5LY*MTczMzM4MDgzMy4xLjEuMTczMzM4MDg3My4zMC4wLjA.
// https://demo.templatemonster.com/it/demo/229902.html?_gl=1*nxjchy*_gcl_aw*R0NMLjE3MzQxNTE1MjQuQ2owS0NRaUEwLS02QmhDQkFSSXNBRFlxeUwtNG1IY0xjMVJkVVpLTnpQOTRyU0tBNlhFLUlTVjdDVUtOUEQxX0lRM1F3TDN1NnQxclNxd2FBdnN1RUFMd193Y0I.*_gcl_au*MTc0MDM1NTk1NS4xNzMzMzgwODQ0*_ga*NTU3MDM1OTk2LjE3MzMzODA4MzQ.*_ga_FTPYEGT5LY*MTczNDE1MTUyNC4xMy4wLjE3MzQxNTE1MjUuNTkuMC4w
const HomePage = () => {
    const localization = useTranslations('home');
    const generalLocalization = useTranslations('general');

    const [notificationState, setNotificationState] = useState<NotificationObj | null>(null);

    const [isLargeScreen, setIsLargeScreen] = useState<boolean>(true)

    const viewModel = new ProductViewModel();

    let loginSubscription: Subscription | null;
    const loaderService = LoaderService.getInstance();
    const locale = useLocale();
    const [products, setProducts] = useState<Product[]>([])
    const [filteredProducts, setFilteredProducts] = useState<Product[]>([])
    const [showSearchField, setShowSearchField] = useState<boolean>(true)
    const [selectedTab, setSelectedTab] = useState("photovoltaicTab")
    const router: any = useRouter()
    const [userPreferences, setUserPreferences] = useState<String | null>(null)
    const [sendConsentToGoogle, setSendConsentToGoogle] = useState<UserPreferences | undefined>(undefined)

    const colors = {
        primary: "#A92216",
        secondary: "#EA2333",
        // tertiary: "#F6DB63"
        tertiary: "#FFFFFF"
    }

    useEffect(() => {
        TweenOne.plugins.push(Children);
        setUserPreferences(window.localStorage.getItem(Constants.userPrivacyPrefsKey))

        viewModel.retrieveProducts().subscribe({
            next: value => {
                LoggerService.getLogger().debug(`<<< PRODUCTS: ${JSON.stringify(value)}`)
                loaderService.isLoading.next(false)
                setProducts(value)
                setFilteredProducts(value)
            },
            error: error => {
                loaderService.isLoading.next(false)

                LoggerService.getLogger().debug(`error: ${JSON.stringify(error)}`)

                const parsedError = JSON.parse(JSON.stringify(error))
                const errorCode = error.error.statusCode;
                const errorMessage = error.error.message;

                const notificationObj = {
                    type: "error",
                    title: generalLocalization("error"),
                    description: errorMessage
                }
                setNotificationState(notificationObj)
            }
        })
        LoggerService.getLogger().debug(`<<< router: ${locale}`)
        loaderService.isLoading.next(false)

        window.addEventListener("resize", handleResize);
        setIsLargeScreen(Helper.checkLargeScreen())
        setShowSearchField(Helper.isScreenLargerThan(MediaQueryEnum.lg.minWidth))
        LoggerService.getLogger().debug(`<<< lg: ${MediaQueryEnum.lg.minWidth}`)
        function handleResize() {
            setIsLargeScreen(Helper.checkLargeScreen())
            // setDrawerOpened(false)
            setShowSearchField(Helper.isScreenLargerThan(MediaQueryEnum.lg.minWidth))

        }

         return () => {
             if (loginSubscription != null) {
                loginSubscription.unsubscribe();
                loginSubscription = null
                LoggerService.getLogger().debug("<<< UNSUSCRIBE LOGIN")
             }
             window.removeEventListener("resize", handleResize)
         };
    }, []);



    return (
        <ConfigProvider
            theme={{
                components: {
                    Menu: {
                        activeBarHeight: 2,
                        collapsedWidth: 20,
                        horizontalItemHoverColor: colors.secondary,
                        itemColor: colors.primary,
                        itemHoverColor: colors.secondary,
                        itemBg: colors.tertiary,
                        itemHeight: isLargeScreen ? 20 : 50,
                        horizontalLineHeight: 10,
                        horizontalItemSelectedColor: colors.secondary,
                        lineType: "none"
                    },
                    Button: {
                        defaultBg: colors.primary,
                        defaultColor: colors.tertiary,
                        defaultHoverBg: colors.secondary,
                        defaultHoverColor: colors.tertiary,
                        defaultActiveBg: colors.secondary,
                        defaultActiveColor: colors.tertiary,
                        defaultActiveBorderColor: colors.tertiary,
                        defaultHoverBorderColor: colors.primary
                    },
                    Input: {
                        activeBorderColor: colors.primary,
                        hoverBorderColor: colors.primary,
                        activeShadow: "0 0 0 2px rgba(169, 34, 22, 0.1)"
                    },
                    Select: {
                        activeOutlineColor: "transparent",
                        lineType: "none"
                    },
                    Tabs: {
                        itemSelectedColor: colors.primary,
                        itemHoverColor: colors.secondary,
                        itemActiveColor: colors.primary,
                        inkBarColor: colors.primary
                    }
                },
                token: {
                    fontFamily: inter.style.fontFamily,

                }
            }}
>
            <Layout>
                <Header style={{height: "auto",backgroundColor: colors.tertiary }}>
                    <HeaderComponent
                        products={products}
                        colors={colors}
                        onFilterProducts={(filteredProducts: Product[]) => {
                            setFilteredProducts(filteredProducts)
                            router.replace("#products")
                        }}
                        onMenuClick={(itemKey: React.Key) => {
                            if (itemKey==="certifications") {
                                setSelectedTab("certificationsTab")
                            }
                        }}
                    />
                </Header>
                <Content style={{ padding: "0 0 48px 0", backgroundColor: `${colors.tertiary}` }}>

                    <CarouselComponent />

                    <section id="products">
                        <h1 className="sectionTitle">{localization("products")}</h1>
                        <div className="sectionBar"></div>
                        <ProductsComponent filteredProducts={filteredProducts} />
                    </section>

                    <section id="company">
                        <h1 className="sectionTitle">{localization("company")}</h1>
                        <div className="sectionBar"></div>
                        <CompanyComponent colors={colors} selectedTab={selectedTab} onSelectTab={(activeKey) => {
                            setSelectedTab(activeKey)
                        }} />
                    </section>

                    <section id="stats">
                        <h1 className="sectionTitle">{localization("stats")}</h1>
                        <div className="sectionBar"></div>
                        <StatsComponent colors={colors} />
                    </section>

                    <section id="contacts">
                        <h1 className="sectionTitle">{localization("contacts")}</h1>
                        <div className="sectionBar"></div>
                        <ContactComponent />
                    </section>

                    {(() => {
                        if (userPreferences === null) {
                            return <CookieBanner onUserPropertySent={prefs => {
                                LoggerService.getLogger().debug(`<<< USER PREFS: ${JSON.stringify(prefs)}`)
                                setUserPreferences(JSON.stringify(prefs))
                                setSendConsentToGoogle(prefs)
                            }}/>
                        }
                    })()}

                    {(() => {
                        if (sendConsentToGoogle !== undefined) {
                            const consent = sendConsentToGoogle.statistics ? "granted" : "denied"
                            return <Script id="google-analytics-send-consent" strategy="afterInteractive">
                                {`
                                  gtag(
                                    'consent', 
                                    'default', 
                                    {
                                      'ad_storage': '${consent}',
                                      'ad_user_data': '${consent}',
                                      'ad_personalization': '${consent}',
                                      'analytics_storage': '${consent}'
                                    }
                                  );
                                `}
                            </Script>
                        }
                    })()}

                </Content>
                <Footer id="colophon" style={{ textAlign: 'center', backgroundColor: colors.primary, color: colors.tertiary, padding: "24px 0" }}>
                    <FooterComponent />
                </Footer>
            </Layout>
        </ConfigProvider>
    );
};

export default HomePage;