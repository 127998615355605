import {NetworkResult} from "@/src/network/models/networkResult";

export class BaseRepository {
    protected createNewPromise<T>(result: NetworkResult<T>): Promise<T> {
        return new Promise((resolve, reject) => {
            if (result.response?.data !== undefined) {
                resolve(result.response.data)
            } else {
                reject()
            }
        });
    }
}