import {Constants} from "@/src/lib/constants";

export abstract class Helper {
    public static isEmptyString = (str: string | undefined | null) => {
        switch (str) {
            case undefined:
            case null:
                return true
            default:
                return !(!!str.trim())
        }
    }

    public static checkLargeScreen = () => {
        return window.innerWidth >= Constants.largeScreenBreakpoint
    }

    public static isScreenLargerThan = (width: number) => {
        return window.innerWidth >= width
    }

    public static decode = (str: string):string => Buffer.from(str, 'base64').toString('binary');
    public static encode = (str: string):string => Buffer.from(str, 'binary').toString('base64');
}