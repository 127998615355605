import { Observable, from } from 'rxjs';
import { LoggerService } from '@/src/services/logger';
import {Product} from "@/src/entities/product";
import {ProductRepository} from "@/src/repositories/productRepository";

export class ProductInteractor {
     private repository: ProductRepository

     public constructor() {
        this.repository = new ProductRepository();
        LoggerService.getLogger().debug("create user interactor")
     }

     public retrieveProducts(): Observable<Product[]> {
        const promise = this.repository.retrieveProducts()
        return from(promise)
     }

}