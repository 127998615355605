import {EndpointEncoding, EndpointMethod, NetworkEndpoint} from '@/src/network/models/networkEndpoint';
import {NetworkResult} from '@/src/network/models/networkResult';
import {LoggerService} from "@/src/services/logger";
import {NetworkResponse} from "@/src/network/models/networkResponse";
import {JsonService} from "@/src/network/jsonService";

export class JsonClient implements JsonService {

    constructor() {
          LoggerService.getLogger().debug("GESTIOWSClient constructor")
    }

    public async sendRequest<T>(json: string): Promise<(NetworkResult<T>)> {
        LoggerService.getLogger().debug("<<< json:" + json)

        try {
            const response = await fetch(json);
            const isError = !response.ok;
            LoggerService.getLogger().debug(`<<< iserror: ${isError}`);
            const data = await response.json();
            LoggerService.getLogger().debug(`<<< response res: ${JSON.stringify(response)}`);

            LoggerService.getLogger().debug(`<<< data: ${JSON.stringify(data)}`);
            if (isError) {
                return new Promise((resolve_1, reject_1) => {
                    if (data.statusCode != null && data.message != null) {
                        const networkResult_1 = new NetworkResult(undefined, data);
                        reject_1(networkResult_1);
                    } else {
                        const genericError_1 = {"error": {"statusCode": 500, "message": "Generic error"}};
                        const networkResult_2 = new NetworkResult(undefined, genericError_1.error);
                        reject_1(networkResult_2);
                    }
                });
            }

            return new Promise((resolve, reject) => {
                const networkResult_4: NetworkResult<T> = new NetworkResult(new NetworkResponse<T>(response.status, response.statusText, data), undefined);
                resolve(networkResult_4);
            });
        } catch (e) {
        LoggerService.getLogger().debug(`<<< CATCH ${e}`);
        const err = {"error": {"statusCode": 500, "message": "Generic error"}};
        const networkResultCatch = new NetworkResult(undefined, err.error);
        return new Promise((resolve, reject) => {
            reject(networkResultCatch);
        });
    }

    }

}