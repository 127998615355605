import {Button, Divider, Drawer, Flex, Image, Menu, Select, Space} from "antd";
import {MenuOutlined, SearchOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {useLocale, useTranslations} from "next-intl";
import {LoggerService} from "@/src/services/logger";
import {Locale, redirect, usePathname, useRouter} from "@/src/i18n/routing";
import {useParams} from "next/navigation";
import {Product} from "@/src/entities/product";
import {MediaQueryEnum} from "@ant-design/pro-utils/lib/useMediaQuery";
import useWindowDimensions from "@/src/hooks/useWindowDimensions";
import {inter} from "@/src/app/fonts";
import {MenuItemType} from "antd/lib/menu/interface";
import {Constants} from "@/src/lib/constants";

type Props = {
    products: Product[];
    colors: {primary: string, secondary: string, tertiary: string}
    onFilterProducts: (filteredProducts: Product[]) => void
    onMenuClick: (itemKey: React.Key) => void
};
const HeaderComponent = (props: Props) => {
    const localization = useTranslations('header');
    const generalLocalization = useTranslations('general');
    const router: any = useRouter()
    const pathname = usePathname();
    const params = useParams();
    const locale = useLocale();
    const [drawerOpened, setDrawerOpened] = useState(false)
    const {width, height} = useWindowDimensions()

    const handleLanguageChange = (value: string) => {
        LoggerService.getLogger().debug(`selected ${value}`);
        const nextLocale = value as Locale;

        router.replace(
            {pathname, params},
            {locale: nextLocale}
        );
    };

    const onProductTypeChange = (type: string) => {
        LoggerService.getLogger().debug(`<<< type: ${type}`)
        if (type===undefined) {
            props.onFilterProducts(props.products)
            return
        }
        const filteredProducts = props.products.filter(value => {
            return value.type == type
        })
        props.onFilterProducts(filteredProducts)
    }

    const items = [
        {key: "company", label: localization("menuCompany")},
        {key: "products", label: localization("menuProducts")},
        {key: "catalog", label: localization("menuCatalog")},
        {key: "certifications", label: localization("menuCertifications")},
        {key: "contacts", label: localization("menuContacts")},
    ]

    useEffect(() => {
        setDrawerOpened(false)
    }, [useWindowDimensions()]);

    const onMenuItemClicked = (item: MenuItemType) => {
        switch(item.key) {
            case "company":
                router.replace("#company");
                break
            case "products":
                router.replace("#products")
                break
            case "catalog":
                window.open("/assets/catalog.pdf", "__blank")
                break
            case "certifications":
                router.replace("#company")
                break
            case "contacts":
                router.replace("#contacts")
                break
            default:
                break
        }
        props.onMenuClick(item.key)
    }

    return (
        <div style={{fontFamily: `${inter.style.fontFamily}`}}>
            <Flex justify={"space-between"} style={{marginTop: 10}} align={"center"}>
                <Flex justify={"start"} style={{marginTop: 10}} align={"center"}>

                <Image alt="" src="/assets/place.png" height={32} preview={false} style={{verticalAlign: "unset", width: "auto"}}/>
                    <div style={{margin: "0 10px"}}>
                        <p style={{
                            margin: 0,
                            padding: 0,
                            lineHeight: "normal",
                            fontSize: 14
                        }}>{Constants.companyAddress}</p>
                        <p style={{marginTop: 2, padding: 0, lineHeight: "normal", fontSize: 12}}>{Constants.companyCity}</p>
                    </div>
                </Flex>
                <Flex justify={"start"} style={{marginTop: 10}} align={"center"}>
                    <Image alt="" src="/assets/headphones.png" height={32} preview={false} style={{verticalAlign: "unset", width: "auto"}}/>
                    <div style={{margin: "0 10px"}}>
                        <p style={{
                            margin: 0,
                            padding: 0,
                            lineHeight: "normal",
                            fontWeight: 600,
                            fontSize: 14
                        }}>{localization("support")}</p>
                        <p style={{marginTop: 2, padding: 0, lineHeight: "normal", fontSize: 12}}>{Constants.companyPhone}</p>
                    </div>
                    <Button
                        className={"languageSelector"}
                        onClick={() => handleLanguageChange(locale == "it" ? "en" : "it")}
                    >
                        {locale == "it" ? "EN" : "IT"}
                    </Button>
                </Flex>



            </Flex>
            <Divider style={{marginTop: 10, marginBottom: 20}}/>
            <Flex align={"center"} justify={"start"}>

                <Flex flex={"fit-content"}>
                    <Image
                        // height={100}
                        src="/assets/logo.png"
                        preview={false}
                        style={{maxHeight: 110, width: "auto"}}
                        alt=""
                    />
                </Flex>
                {(() => {
                    if (width >= MediaQueryEnum.lg.minWidth) {
                        return (
                            <Flex justify={"flex-start"} flex={"fit-content"}>
                                <Space.Compact style={{width: '100%'}}>
                                    <Select
                                        className="productFilter"
                                        onChange={onProductTypeChange}
                                        allowClear={true}
                                        options={[
                                            {value: 'dragees', label: localization("option1")},
                                            {value: 'marshmallow', label: localization("option2")},
                                            {value: 'marshmallow-XXL', label: localization("option3")},
                                            {value: 'meringue', label: localization("option4")},
                                            {value: 'meringue-XXL', label: localization("option5")},
                                        ]}
                                        size={"large"}
                                    />
                                    <Button className="searchButton" type="primary" disabled size={"large"}
                                            style={{background: props.colors.primary}} icon={<SearchOutlined/>}/>
                                </Space.Compact>

                            </Flex>
                        )
                    }
                })()}
                <Flex align={"center"} justify={"end"}>
                    {(() => {

                        if (width >= MediaQueryEnum.md.minWidth) {
                            return (
                                <>
                                    <Menu
                                        theme="light"
                                        mode="horizontal"
                                        items={items}
                                        disabledOverflow={true}
                                        style={{minWidth: 0, flex: "auto"}}
                                        onClick={(item)=> onMenuItemClicked(item)}
                                    />
                                </>
                            )
                        } else {
                            return (
                                <>
                                    <Button
                                        type={"default"}
                                        icon={<MenuOutlined/>}
                                        onClick={(e) => setDrawerOpened(!drawerOpened)}
                                    />
                                    <Drawer open={drawerOpened} onClose={() => setDrawerOpened(false)}>
                                        <Menu
                                            theme="light"
                                            mode="vertical"
                                            items={items}
                                            disabledOverflow={true}
                                            onClick={(item)=> onMenuItemClicked(item)}
                                        />
                                    </Drawer>
                                </>
                            )
                        }
                    })()}

                </Flex>

            </Flex>
            <Divider style={{marginTop: 20, marginBottom: 20}}/>
        </div>
    );
}

export default HeaderComponent