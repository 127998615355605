import {Card, Col, Flex, Row} from "antd";
import {
    BankOutlined,
    ClockCircleOutlined,
    TeamOutlined,
    UserOutlined
} from "@ant-design/icons";
import React, {ReactNode} from "react";
import {useTranslations} from "next-intl";

type Props = {
    colors: {primary: string, secondary: string, tertiary: string}
}
const StatsComponent = (props: Props) => {
    const localization = useTranslations('stats');

    const cardStyle: React.CSSProperties = {
        width: "100%",
        border: "1px solid #eee",
        color: props.colors.primary
    }

    const iconStyle: React.CSSProperties = {
        fontSize: 44,
        marginRight: 16
    }

    const getStat = (iconComponent: ReactNode, title: string, text: string) => {
        return (
            <Card variant={"borderless"} style={cardStyle}>
                <Flex vertical={false}>
                    {iconComponent}
                    <Flex vertical style={{fontSize: 24}}>
                        <div>{title}</div>
                        <div style={{color: "#000", fontSize: 18}}>{text}</div>
                    </Flex>
                </Flex>
            </Card>
        )
    }

    return (
        <Row gutter={[16, 16]} style={{margin: "16px"}}>
            <Col xs={24} sm={12} md={12} lg={6}>
                {
                    getStat(
                        <TeamOutlined style={iconStyle} />,
                        localization("stat1"),
                        "15"
                    )
                }
            </Col>
            <Col xs={24} sm={12} md={12} lg={6}>
                {
                    getStat(
                        <UserOutlined style={iconStyle} />,
                        localization("stat2"),
                        "Marco Chirico"
                    )
                }
            </Col>
            <Col xs={24} sm={12} md={12} lg={6}>
                {
                    getStat(
                        <ClockCircleOutlined style={iconStyle} />,
                        localization("stat3"),
                        "1969"
                    )
                }
            </Col>
            <Col xs={24} sm={12} md={12} lg={6}>
                {
                    getStat(
                        <BankOutlined style={iconStyle} />,
                        localization("stat4"),
                        "SRL"
                    )
                }
            </Col>
        </Row>
    )
}

export default StatsComponent