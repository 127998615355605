'use client'
import { notification } from 'antd';
import React, {Dispatch, FunctionComponent, SetStateAction} from 'react'
import { LoggerService } from '@/src/services/logger';
import {ArgsProps} from "antd/lib/notification/interface";
import {DispatchStatePromise} from "next/dist/shared/lib/router/action-queue";

type Props = {
    notificationState: NotificationObj | null
    setNotificationState: Dispatch<SetStateAction<NotificationObj | null>>
}

export interface NotificationObj {
    type: string
    title: string
    description: string
}

const NotificationComponent: FunctionComponent<Props> = (props) => {
    const [api, contextHolder] = notification.useNotification();
    LoggerService.getLogger().debug("<<< " + JSON.stringify(props.notificationState));
    const notificationObj = props.notificationState
    if (notificationObj !== null) {
      setTimeout(() => {
          const notificationConfig: ArgsProps = {
              message: notificationObj.title,
              description: notificationObj.description
          }

          switch (notificationObj.type) {
              case "success":
                  api.success(notificationConfig);
                  break;
              case "error":
                  api.error(notificationConfig);
                  break;
              case "warning":
                  api.warning(notificationConfig);
                  break;
              default:
                  api.info(notificationConfig);
                  break;
          }
          props.setNotificationState(null)
      });
    }

    return (
      <>
          {contextHolder}
      </>
    );
};
export default NotificationComponent;