import {SizeType} from "antd/lib/config-provider/SizeContext";
export abstract class Constants {
    public static privacyManagerName: string = "Dolciaria Chirico srl"
    public static privacyManagerAddressRow1: string = "Via 1 Maggio zona PIP 18"
    public static privacyManagerAddressRow2: string = "73020 Carpignano Salentino, Lecce - Italy"
    public static privacyManagerPhone: string = "+39 0836 586367"
    public static privacyManagerEmail: string = "contatti@dolciariachirico.com"
    public static privacyManagerVat: string = "IT05236010756"

    public static companyName: string = 'Dolciaria Chirico srl'
    public static companyFullAddress: string = 'Via 1 Maggio zona PIP 18, 73020 Carpignano Salentino, Lecce - Italy'
    public static companyAddress: string = 'Via 1 Maggio 18'
    public static companyCity: string = 'Carpignano Salentino, Lecce - Italy'
    public static companyPhone: string = '0039 (0)836 586367'
    public static companyEmail: string = "contatti@dolciariachirico.com"
    public static companyVat: string = "IT05236010756"
    public static companyRea: string = "352098"
    public static companyCCIAA: string = "C.C.I.A.A. Lecce"

    public static gestioWS_baseURLString: string = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://sdi.execom.it'
    public static gestioWS_apikey: string = "r9vWQeLNIqt0X86By0o9FENKseBVOm2OnCUilWzlpOqD1PhX9kDq3c8MtSi7kKNh"
    public static largeScreenBreakpoint: number = 768
    public static apiPrefix: string = '/api/v1'
    public static userPrivacyPrefsKey: string = "userPrivacyPrefs"
    public static apiUrl: string = 'http://localhost:8080/api/v1'
    public static iconSize: string = '20px'
    public static componentsSize: SizeType = 'large'
    public static loginFormLayout: object = {
        wrapperCol: {
            xs: {offset: 4, span: 16},
            sm: {offset: 6, span: 12},
            md: {offset: 7, span: 10},
            lg: {offset: 8, span: 8},
            xl: {offset: 9, span: 6},
        }
    }
}