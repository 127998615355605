import {Button, Col, Form, Image, Input, Row} from "antd"
import Title from "antd/lib/typography/Title";
import {useTranslations} from "next-intl";
import {LoggerService} from "@/src/services/logger";
import NotificationComponent, {NotificationObj} from "@/src/components/notification";
import React, {useState} from "react";
import {LoaderService} from "@/src/services/loader";
import TextArea from "antd/lib/input/TextArea";
import contactImg from "@/public/assets/contactImg.jpg"

const ContactComponent = () => {
    const localization = useTranslations('contact');
    const generalLocalization = useTranslations('general')
    const [notificationState, setNotificationState] = useState<NotificationObj | null>(null);
    const loaderService = LoaderService.getInstance();
    const [form] = Form.useForm();

    const contactImageDiv: React.CSSProperties = {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url(${contactImg.src})`,
        width: "100%",
        height: "100%",
        minHeight: 200,
        backgroundColor: "red"
    }

    type FieldType = {
        name?: string;
        email?: string
        message?: string;
    };

    const handleSubmit = (values: any) => {
        loaderService.isLoading.next(true)

        const apiEndpoint = '/api/email';
        LoggerService.getLogger().debug(`<<< data: ${JSON.stringify(values)}`)
        fetch(apiEndpoint, {
            method: 'POST',
            body: JSON.stringify(values),
        })
            .then((res) => res.json())
            .then((response) => {
                LoggerService.getLogger().debug(`<<< MAIL RES: ${JSON.stringify(response)}`)
                if (response.error === undefined) {
                    const notificationObj = {
                        type: "success",
                        title: generalLocalization("done") + "!",
                        description: response.message
                    }
                    form.resetFields()

                    setNotificationState(notificationObj)
                } else {
                    const notificationObj = {
                        type: "error",
                        title: generalLocalization("error"),
                        description: response.error
                    }
                    setNotificationState(notificationObj)
                }

                loaderService.isLoading.next(false)
            })
            .catch((err) => {
                const notificationObj = {
                    type: "error",
                    title: generalLocalization("error"),
                    description: err
                }
                setNotificationState(notificationObj)
                loaderService.isLoading.next(false)
            });
    }

    const onFinishFailed = (errorInfo: any) => {
        LoggerService.getLogger().debug(`<<< form failed: ${errorInfo}`)
    };

    return (
        <>
            <NotificationComponent notificationState={notificationState} setNotificationState={setNotificationState} />
            <Row gutter={16} style={{margin: 24}}>
                <Col xs={24} sm={12}>
                    <div style={contactImageDiv}></div>
                </Col>
                <Col xs={24} sm={12}>
                    <Form
                        name="contactForm"
                        onFinish={handleSubmit}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        form={form}
                    >
                        <Form.Item<FieldType>
                            name="name"
                            rules={[{ required: true, message: generalLocalization('form.error.required', {fieldName: localization("nameLbl")}) }]}
                        >
                            <Input size="large" placeholder={generalLocalization('form.placeholder.insertField', {fieldName: localization("nameLbl")})} />
                        </Form.Item>
                        <Form.Item<FieldType>
                            name="email"
                            rules={[
                                {
                                    required: true, message: generalLocalization('form.error.required', {fieldName: localization("emailLbl")})
                                },
                                {
                                    type: 'email',
                                    message: generalLocalization('form.error.notValidEmail', {fieldName: localization("emailLbl")}),
                                },
                            ]}
                        >
                            <Input size = "large" placeholder={generalLocalization('form.placeholder.insertField', {fieldName: localization("emailLbl")})} />
                        </Form.Item>

                        <Form.Item<FieldType>
                            name="message"
                            rules={[{ required: true, message: generalLocalization('form.error.required', {fieldName: localization("messageLbl")}) }]}
                        >
                            <TextArea className="contactTextArea" size = "large" rows={5} placeholder={generalLocalization('form.placeholder.insertField', {fieldName: localization("messageLbl")})} />
                        </Form.Item>
                        <Form.Item style={{textAlign: "right"}}>
                            <Button size={"large"} htmlType="submit" block={false}>{localization('sendBtn')}</Button>
                        </Form.Item>


                    </Form>
                </Col>
            </Row>
        </>

    )
}

export default ContactComponent