import bg1 from "@/public/assets/bg/bg1.png";
import {Button, Carousel, Spin} from "antd";
import bg2 from "@/public/assets/bg/bg2.png";
import bg3 from "@/public/assets/bg/bg3.png";
import React, {CSSProperties, useEffect, useState} from "react";
import useWindowDimensions from "@/src/hooks/useWindowDimensions";
import {MediaQueryEnum} from "@ant-design/pro-utils/lib/useMediaQuery";
import {inter} from "@/src/app/fonts";
import {useTranslations} from "next-intl";
import Link from "next/link";

const CarouselComponent = () => {
    const [currentCarouselSlide, setCurrentCarouselSlide] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const localization = useTranslations('carousel');

    const carouselContentStyle: React.CSSProperties = {
        margin: 0,
        height: '380px',
        color: '#fff',
        textAlign: 'center',
        position: "relative",
    };

    const carouselContentStyleLg: React.CSSProperties = {
        margin: 0,
        height: '500px',
        color: '#fff',
        textAlign: 'center',
        position: "relative",
    };

    const carouselImageContentStyle: React.CSSProperties = {
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height:"100%"
    }

    const carouselTextContainerStyleLargeScreen: React.CSSProperties = {
        position: "absolute",
        top: 0,
        bottom: 0,
        left:0,
        width: "100%",
        backgroundColor: "rgba(0,0,0, 0.45)",
    }

    const carouselTextContainerStyle: React.CSSProperties = {
        position: "absolute",
        right: 0,
        bottom: 0,
        left:0,
        top:0,
        backgroundColor: "rgba(0,0,0, 0.45)",
    }

    const carouselTextStyle: React.CSSProperties = {
        position: "absolute",
        bottom: "30%",
        left: 30,
        right: 0,
        paddingBottom: 0,
        textAlign: "left"
    }

    const afterCarouselChange = (currentSlide: number) => {
        setCurrentCarouselSlide(currentSlide)
    }

    const screenWidth: number = useWindowDimensions().width
    useEffect(() => {
        setIsLoading(false)
    }, []);

    const getTextContainerClass = () => {
        if (screenWidth >= MediaQueryEnum.md.minWidth) {
            return carouselTextContainerStyleLargeScreen
        } else {
            return carouselTextContainerStyle
        }
    }
    const getCarouselContent = (bgImage: string, title: string, subtitle: string, buttonText: string, slideIndex: number) => {
        return (
            <div key={slideIndex} style={{borderRadius: 20}}>
                <div style={screenWidth >= MediaQueryEnum.md.minWidth ? carouselContentStyleLg : carouselContentStyle}>
                    <div  style={{backgroundImage: `url(${bgImage})`, ...carouselImageContentStyle}}></div>
                    {/*<div style={getTextContainerClass()}>*/}
                    {/*    <div style={carouselTextStyle}>*/}
                    {/*        {(() => {*/}
                    {/*            if (currentCarouselSlide === slideIndex) {*/}
                    {/*                return (*/}
                    {/*                    <>*/}
                    {/*                        <h2 className={`carouselTitle ${inter.className}`}>{title}</h2>*/}
                    {/*                        <p className={`carouselSubtitle ${inter.className}`}>{subtitle}</p>*/}
                    {/*                        <Link href="/assets/catalog.pdf" target="_blank">*/}
                    {/*                            <Button className={`carouselButton`}>{buttonText}</Button>*/}
                    {/*                        </Link>*/}
                    {/*                    </>*/}
                    {/*                )*/}
                    {/*            }*/}
                    {/*        })()}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        )
    }

    return (
        <>
        {(() => {
            if (isLoading) {
                return (
                    <div style={{backgroundColor: "#efefef", display: "flex", alignItems: "center", ...carouselContentStyle}}>
                        <Spin style={{margin: "0 auto"}} size={"large"} />
                    </div>
                )
            } else {
                return (
                    <>
                        <Carousel afterChange={afterCarouselChange} infinite autoplay dots={false} draggable autoplaySpeed={3500} effect={"scrollx"}>
                            {(() => {
                                return (
                                    [
                                        getCarouselContent(bg2.src, localization("slide2Title"), localization("slide2Subtitle"), localization("slideBtnText"), 1),
                                        getCarouselContent(bg1.src, localization("slide1Title"), localization("slide1Subtitle"), localization("slideBtnText"), 0),
                                        getCarouselContent(bg3.src, localization("slide3Title"), localization("slide3Subtitle"), localization("slideBtnText"), 2),
                                    ]
                                )
                            })()}
                        </Carousel>
                    </>
                )
            }

        })()}
        </>
    )
}

export default CarouselComponent