import {Carousel, Col, Image, Row, Tabs} from "antd"
import Title from "antd/lib/typography/Title";
import Paragraph from "antd/lib/typography/Paragraph";
import {useTranslations} from "next-intl";
import React from "react";
import {CheckCircleOutlined} from "@ant-design/icons";
import bg1 from "@/public/assets/bg/bg1.png";
import bg2 from "@/public/assets/bg/bg2.png";
import bg3 from "@/public/assets/bg/bg3.png";

type Props = {
    colors: {primary: string, secondary: string, tertiary: string}
    selectedTab: string
    onSelectTab: (activeKey: string) => void
};
const CompanyComponent = (props: Props) => {

    const localization = useTranslations("company")

    const getTabContent = (imagePath: string, text: string, points: string[]) => {
        return (
            <Row gutter={16}>
                <Col xs={12} >
                    <Image preview={false} src={imagePath} alt="" style={{width: "95%", border: "1px solid transparent", borderRadius: 8}} />
                </Col>
                <Col xs={12}>
                    <Paragraph>{text}</Paragraph>
                    <ul style={{listStyle: "none"}}>
                        {(()=> {
                            return points.map((point, index) => {
                                return (<li key={index} style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "10px 0 0"}}><CheckCircleOutlined style={{color: props.colors.primary, fontSize: 16, marginRight: 10}}/>{point}</li>)
                            })
                        })()}
                    </ul>
                </Col>
            </Row>
        )
    }
    const tabItems = [
        {
            key: 'photovoltaicTab',
            label: localization("tab2Title"),
            children: getTabContent(
                "/assets/photovoltaic.jpg",
                localization("tab2ContentText"),
                [
                    localization("tab2Point1"),
                    localization("tab2Point2")
                ]
            )
        },
        {
            key: 'certificationsTab',
            label: localization("tab3Title"),
            children: getTabContent(
                "/assets/certifications.png",
                localization("tab3ContentText"),
                [
                    localization("tab3Point1"),
                    localization("tab3Point2")
                ]
            )
        },
        // {
        //     key: 'qualityTab',
        //     label: localization("tab1Title"),
        //     children: getTabContent(
        //         "/assets/quality.jpg",
        //         localization("tab1ContentText"),
        //         [
        //             localization("tab1Point1"),
        //             localization("tab1Point2"),
        //             localization("tab1Point3"),
        //         ]
        //     )
        // },
    ]

    return (
        <div style={{backgroundColor:"#FAFAFA", padding: "50px 0"}}>
            <Row gutter={16} style={{margin: 0}}>
                <Col xs={24} sm={24} md={24} lg={12} style={{textAlign: "center"}}>
                    <Carousel infinite autoplay dots={false} draggable autoplaySpeed={2500} effect={"scrollx"} style={{border: `4px solid ${props.colors.primary}`, padding: 16, maxWidth:"100%", marginBottom: 10}}>
                        {(() => {
                            return (
                                [
                                    <Image key="1" preview={false} src="/assets/company1.jpg" alt="" />,
                                    <Image key="2" preview={false} src="/assets/company2.jpg" alt="" />,
                                    <Image key="3" preview={false} src="/assets/quality.jpg" alt="" />,
                                    <Image key="4" preview={false} src="/assets/xray.jpg" alt="" />,
                                ]
                            )
                        })()}
                    </Carousel>
                    <Row gutter={16} style={{margin: 16}}>
                        <Col xs={24}>
                            <ul style={{listStyle: "none"}}>
                                <li key="tab11" style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "10px 0 0"}}><CheckCircleOutlined style={{color: props.colors.primary, fontSize: 16, marginRight: 10}}/>{localization("tab1Point1")}</li>
                                <li key="tab12" style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "10px 0 0"}}><CheckCircleOutlined style={{color: props.colors.primary, fontSize: 16, marginRight: 10}}/>{localization("tab1Point2")}</li>
                                <li key="tab13" style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "10px 0 0"}}><CheckCircleOutlined style={{color: props.colors.primary, fontSize: 16, marginRight: 10}}/>{localization("tab1Point3")}</li>
                                <li key="tab14" style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "10px 0 0"}}><CheckCircleOutlined style={{color: props.colors.primary, fontSize: 16, marginRight: 10}}/>{localization("xray")}</li>
                            </ul>
                        </Col>
                    </Row>

                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                    <Title level={1} style={{textTransform: "uppercase", textAlign: "left", letterSpacing: -2, fontSize: 55}}>{localization("title")}</Title>
                    <Paragraph style={{fontSize: 16}}>{localization("subtitle")}</Paragraph>
                    <Tabs items={tabItems} activeKey={props.selectedTab} onChange={(activekey) => {
                        props.onSelectTab(activekey)
                    }} />
                </Col>
            </Row>
        </div>
    )

}

export default CompanyComponent