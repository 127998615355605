import pino, {Logger, LoggerOptions} from 'pino';

export class LoggerService {
  private static instance: LoggerService
  private static loggerOption: LoggerOptions = {
      name: "MyLogger",
      level: process.env.NODE_ENV === "development" ? "debugLog" : "infoLog",
      customLevels: {
          fatalLog: 70,
          errorLog: 60,
          warnLog: 50,
          infoLog: 40,
          debugLog: 20,
          traceLog: 10
      },
      browser: {
          asObject: true
      }
  }
  private logger: Logger = pino(LoggerService.loggerOption)

  private constructor() {
      if(LoggerService.instance) {
          throw new Error("Error: Instantiation failed: Use LoaderService.getInstance() instead of new.");
      }
      LoggerService.instance = this;
  }

  static getInstance(): LoggerService {
     LoggerService.instance = LoggerService.instance || new LoggerService();
     return LoggerService.instance;
  }

  static getLogger() {
    return LoggerService.getInstance().logger
  }

}