import {Col, Flex, Row, Typography} from "antd";
import {EnvironmentOutlined, MailOutlined, PhoneOutlined} from "@ant-design/icons";
import React from "react";
import {useTranslations} from "next-intl";
import {Constants} from "@/src/lib/constants";
const { Text, Link } = Typography;


const FooterComponent = () => {
    const localization = useTranslations('footer');

    return (
        <>
            <Row gutter={[16, 16]} style={{margin: "16px"}}>
                <Col xs={24} sm={12} md={6}>
                    <Flex vertical justify={"start"} align={"flex-start"}>
                        <h1 style={{textAlign: "left", marginBottom: 20}}>{Constants.companyName}</h1>
                        <Flex vertical>
                            <h3 className={"footerLine noMargin"}>{localization("vatNumber")}</h3>
                            <p className={"footerLine noMargin"}>{Constants.companyVat}</p>
                        </Flex>
                        <Flex vertical style={{marginTop: 15}}>
                            <h3 className={"footerLine noMargin"}>{localization("reaNumber")}</h3>
                            <p className={"footerLine noMargin"}>{Constants.companyRea}</p>
                        </Flex>
                        <Flex vertical style={{marginTop: 15}}>
                            <h3 className={"footerLine noMargin"}>{localization("registered")}</h3>
                            <p className={"footerLine noMargin"}>{Constants.companyCCIAA}</p>
                        </Flex>
                        <Flex vertical style={{marginTop: 15}}>
                            <p className={"footerLine noMargin"}><a href={"privacy"} target={"_blank"}>{localization("privacy")}</a></p>
                        </Flex>
                    </Flex>
                </Col>
                <Col xs={24} sm={12} md={6}>
                </Col>
                <Col xs={24} sm={12} md={6}>

                </Col>
                <Col xs={24} sm={12} md={6}>
                    <h1 style={{textAlign: "left", marginBottom: 20}}>{localization("contactUs")}</h1>
                    <Flex vertical={false} justify={"start"} align={"center"}>
                        <PhoneOutlined rotate={90} className="footerItem"  />
                        <Flex vertical>
                            <h3 className={"footerLine"}>{localization("phone")}</h3>
                            <p className={"footerLine"}>{Constants.companyPhone}</p>
                        </Flex>
                    </Flex>
                    <Flex vertical={false} justify={"start"} align={"center"} style={{marginTop: 15}}>
                        <MailOutlined className="footerItem" />
                        <Flex vertical>
                            <h3 className={"footerLine"}>{localization("email")}</h3>
                            <p className={"footerLine"}><a href={`mailto:${Constants.companyEmail}`}>{Constants.companyEmail}</a></p>
                        </Flex>
                    </Flex>
                    <Flex vertical={false} justify={"start"} align={"center"} style={{marginTop: 15}}>
                        <EnvironmentOutlined className="footerItem" />
                        <Flex vertical>
                            <h3 className={"footerLine"}>{localization("location")}</h3>
                            <p className={"footerLine"}>{Constants.companyFullAddress}</p>
                        </Flex>
                    </Flex>
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{marginTop: 20, fontSize: 13, fontWeight: 200}}>
                    {`${Constants.companyName} ©${new Date().getFullYear()} Created by Execom Technology`}
                </Col>
            </Row>
        </>
    )
}

export default FooterComponent