import {Card, Carousel, Col, Descriptions, DescriptionsProps, Drawer, Image, List, Row, Tabs, TabsProps} from "antd";
import React, {useEffect, useState} from "react";
import {Product} from "@/src/entities/product";
import {useTranslations} from "next-intl";
import * as process from "process";

type Props = {
    filteredProducts: Product[]
}

const ProductsComponent = (props: Props) => {
    const [tabItems , setTabItems] = useState<TabsProps['items']>([])
    const [tabActiveKey, setTabActiveKey] = useState("1")
    const [isProductModalOpen, setiIsProductModalOpen] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(undefined)
    const [productItems, setProductItems] = useState<DescriptionsProps['items'] >([])
    const [productNutritionalData, setProductNutritionalData] = useState<DescriptionsProps['items'] >([])
    const [productIngredientData, setProductIngredientData] = useState<DescriptionsProps['items'] >([])
    const localization = useTranslations('product');

    useEffect(() => {
        const tabI: TabsProps['items'] = [
            {
                key: '1',
                label: localization("tab1Title"),
                children: <Descriptions bordered items={productItems} layout={"vertical"} labelStyle={{fontWeight: 600}} />
            },
            {
                key: '2',
                label: localization("tab2Title"),
                children:  <Descriptions items={productNutritionalData} bordered layout={"vertical"} labelStyle={{fontWeight: 600}} />
            },
            {
                key: '3',
                label: localization("tab3Title"),
                children:  <Descriptions items={productIngredientData} bordered layout={"vertical"} labelStyle={{fontWeight: 600}} />
            }
        ];

        setTabItems(tabI)
    }, [productItems, productNutritionalData, productIngredientData]);

    const openProductModal = (item: Product) => {
        setSelectedProduct(item)
        setiIsProductModalOpen(true)

        const items: DescriptionsProps['items'] = [
            {
                key: 'name',
                label: localization("name"),
                children: <p>{item.longName}</p>,
                span: 3
            },
            {
                key: 'code',
                label: localization("code"),
                children: <p>{item.code}</p>,
                span: 1
            },
            {
                key: 'type',
                label: localization("type"),
                children: <p>{item.type}</p>,
                span: 2
            },

            {
                key: 'packaging',
                label: item.packagingType,
                children: <p>{item.packagingAmount}</p>,
            },
            {
                key: 'cartons',
                label: localization("cartons"),
                children: <p>{item.cartons}</p>,
            },
            {
                key: 'pallet',
                label: localization("pallets"),
                children: <p>{item.pallet}</p>,
            },
            {
                key: 'palletsize',
                label: localization("palletSize"),
                children: <p>{item.palletSize}</p>,
            },
        ];
        setProductItems(items)

        const nutritionalData: DescriptionsProps['items'] = [
            {
                key: 'servingSize',
                label: localization("servingSize"),
                children: <p>{item.nutritionalData.servingSize}</p>,
                span: 3
            },
            {
                key: 'fat',
                label: localization("fat"),
                children: <p>{item.nutritionalData.fat}</p>,
            },
            {
                key: 'saturedFat',
                label: localization("saturedFat"),
                children: <p>{item.nutritionalData.saturedFat}</p>,
            },
            {
                key: 'carbohydrate',
                label: localization("carbohydrate"),
                children: <p>{item.nutritionalData.carbohydrate}</p>,
            },
            {
                key: 'sugars',
                label: localization("sugars"),
                children: <p>{item.nutritionalData.sugars}</p>,
            },
            {
                key: 'fiber',
                label: localization("fiber"),
                children: <p>{item.nutritionalData.fiber}</p>,
            },
            {
                key: 'protein',
                label: localization("protein"),
                children: <p>{item.nutritionalData.protein}</p>,
            },
            {
                key: 'salt',
                label: localization("salt"),
                children: <p>{item.nutritionalData.salt}</p>,
            },
            {
                key: 'totalCal',
                label: localization("totalCal"),
                children: <p>{item.nutritionalData.totalCals}</p>,
            },
            {
                key: 'totalEnergy',
                label: localization("totalEnergy"),
                children: <p>{item.nutritionalData.totalEnergy}</p>,
            },
        ];
        setProductNutritionalData(nutritionalData)

        const ingredientData: DescriptionsProps['items'] = [
            {
                key: 'ingredients',
                label: localization("ingredients"),
                children: <p>{item.ingredients}</p>,
                span: 3
            },
            {
                key: 'allergens',
                label: localization("allergens"),
                children: <p>{item.allergens === null ? "-" : item.allergens}</p>,
                span: 3
            },
            {
                key: 'shelfLife',
                label: localization("shelfLife"),
                children: <p>{item.shelfLife}</p>,
                span: 3
            },
            {
                key: 'storageConditions',
                label: localization("storageConditions"),
                children: <p>{item.storageConditions}</p>,
                span: 3
            },
        ];
        setProductIngredientData(ingredientData)
    }

    const onModalClose = () => {
        setSelectedProduct(undefined)
        setiIsProductModalOpen(false)
        setTabActiveKey("1")
    }

    const productTabChange = (activeKey: string) => {
        setTabActiveKey(activeKey)
    }

    return (
        <>
            <List
                style={{margin: 16}}
                grid={{
                    gutter: 24,
                    xs: 1,
                    sm: 2,
                    md: 3,
                    lg: 4,
                    xl: 4,
                    xxl: 5,
                }}
                dataSource={props.filteredProducts}
                renderItem={(item) => (
                    <List.Item>
                        <div className="myCard" onClick={() => openProductModal(item)}>
                            <img alt="" src={`${process.env.BASE_URL}/assets/products/${item.image1}.png`} className="cover" style={{borderTopLeftRadius: 8, borderTopRightRadius: 8, marginBottom: 10, height: 200}} />
                            <h4 className="productCardTitle">{item.shortName}</h4>
                            <p className="productCardType">{item.type}</p>
                        </div>
                    </List.Item>
                )}
            />

            <Drawer
                title={selectedProduct?.shortName}
                width={400}
                onClose={onModalClose}
                open={isProductModalOpen}
            >
                <Row>
                    <Col span={24} style={{textAlign: "center"}}>
                        <Carousel arrows dots={false} draggable autoplay >
                            <div>
                                <Image className="productImage" alt="" src={`/assets/products/${selectedProduct?.image1}.png`} preview={false} />
                            </div>
                            {(()=> {
                                if (selectedProduct?.image2) {
                                    return (
                                        <div>
                                            <Image className="productImage" alt="" src={`/assets/products/${selectedProduct?.image2}.png`} preview={false} />
                                        </div>
                                    )
                                }
                            })()}
                        </Carousel>
                    </Col>
                    <Col span={24}>
                        <Tabs items={tabItems} activeKey={tabActiveKey} onChange={productTabChange}/>
                    </Col>
                </Row>
            </Drawer>
        </>
    )
}

export default ProductsComponent